import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Container, Row, Col } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar" style={{
            background: `#2d4152`,
          }}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Container className="d-flex align-items-start flex-column">
          <Row>
            <Col>
              <Nav className="mb-auto" activeKey={pageInfo && pageInfo.pageName}>
                <Link to="/" className="link-no-style">
                  <Nav.Link as="span" eventKey="index">
                    Start
                  </Nav.Link>
                </Link>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav className="mb-auto" activeKey={pageInfo && pageInfo.pageName}>
                <Link to="/charter" className="link-no-style">
                  <Nav.Link as="span" eventKey="charter">
                    Kojencharter
                  </Nav.Link>
                </Link>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav className="mb-auto" activeKey={pageInfo && pageInfo.pageName}>
                <Link to="/booking-request" className="link-no-style">
                  <Nav.Link as="span" eventKey="booking-request">
                    Anfrage
                  </Nav.Link>
                </Link>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav className="mb-auto" activeKey={pageInfo && pageInfo.pageName}>
                <Link to="/contact" className="link-no-style">
                  <Nav.Link as="span" eventKey="contact">
                    Kontakt
                  </Nav.Link>
                </Link>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav className="mb-auto" activeKey={pageInfo && pageInfo.pageName}>
                <Link to="/imprint" className="link-no-style">
                  <Nav.Link as="span" eventKey="imprint">
                    Impressum
                  </Nav.Link>
                </Link>
              </Nav>
            </Col>
          </Row>
        </Container>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
